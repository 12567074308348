export default [
  // { header: "Panel Administrativo", i18n: "Admin" },
  // {
  //   title: "Licencia",
  //   i18n: "License",
  //   name: "admin/License",
  //   icon: "mdi-license"
  // },
  { header: "Módulos", i18n: "Modules" },
  {
    title: "Negocios y Franquicias",
    i18n: "BusinessFranchises",
    group: "business",
    icon: "mdi-group",
    modules: [1, 13, 14, 15, 16, 17, 18],
    items: [
      {
        title: "Estructura Corporativa",
        name: "CorporateStructure",
        i18n: "CorporateStructure",
        group: "corporate-structure",
        icon: "mdi-family-tree",
        module: 1,
        subGroup: true,
        items: [
          {
            name: "MyCompany",
            title: "Usuario",
            i18n: "MyCompany",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'user'],
          },
          {
            name: "adminPanel",
            title: "Principal",
            i18n: "Dashboard",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
          {
            name: "identity",
            title: "CorporateIdentity",
            i18n: "CorporateIdentity",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_identity'],
          },
          {
            name: "companies",
            title: "Companies",
            i18n: "Companies",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
          {
            name: "map",
            title: "GeolocationOfCompanies",
            i18n: "GeolocationOfCompanies",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          }
        ]
      },
      {
        title: "Expansión",
        i18n: "Expansion",
        group: "expansion",
        icon: "mdi-earth",
        module: 13,
        subGroup: true,
        items: [
          {
            name: "AdminPanel",
            title: "Administrador",
            i18n: "Dashboard",
            subGroup: true
          }
        ]
      },
      {
        title: "Elearning",
        group: "elearning",
        icon: "mdi-school",
        i18n: "Elearning",
        subGroup: true,
        module: 14,
        items: [
          {
            name: "AdminPanel",
            title: "Dashboard",
            i18n: "Dashboard",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'elearning_dashboard', 'admin_learning'],
          },
          {
            name: "Courses",
            title: "Courses",
            i18n: "Courses",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_courses'],
          },
          {
            name: "TrainingPerformance",
            title: "TrainingPerformance",
            i18n: "TrainingPerformance",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_learning'],
          },
          {
            name: "UserCourses",
            title: "UserCourses",
            i18n: "UserCourses",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'user'],
          }
        ]
      },
      // {
      //   title: "Coupons",
      //   name: "Coupons",
      //   i18n: "Coupons",
      //   group: "coupons",
      //   icon: "mdi-ticket-percent",
      //   module: 28,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Notificaciones",
      //   name: "Notifications",
      //   i18n: "Notifications",
      //   group: "Notifications",
      //   icon: "mdi-bell-ring",
      //   module: 15,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      {
        title: "Tickets",
        name: "Tickets",
        i18n: "Tickets",
        group: "Tickets",
        icon: "mdi-ticket-account",
        module: 16,
        items: [
          {
            name: "Operational",
            title: "Operational",
            i18n: "Dashboard"
          }
        ]
      },
      {
        title: "Control y Supervisión",
        name: "SupervisionControl",
        i18n: "SupervisionControl",
        group: "supervision-control",
        icon: "mdi-podium-gold",
        subGroup: true,
        module: 17,
        items: [
          {
            name: "Dashboard",
            title: "Dashboard",
            i18n: "Dashboard",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'evaluations_dashboard'],
          },
          {
            name: "Evaluations",
            title: "Evaluations",
            i18n: "Evaluations",
            requiredRoles: ['super_admin', 'admin', 'admin_evaluations', 'auditor_evaluations'],
            subGroup: true
          },
          {
            name: "GlobalAssignedEvaluations",
            title: "GlobalAssignedEvaluations",
            i18n: "GlobalAssignedEvaluations",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_evaluations', 'auditor_evaluations', 'costumer_evaluations'],
          },
          // {
          //   name: "Penalties",
          //   title: "Penalties",
          //   i18n: "Penalties",
          //   subGroup: true
          // },
          {
            name: "Supervisions",
            title: "Supervisions",
            i18n: "Supervisions",
            subGroup: true,
             requiredRoles: ['super_admin', 'admin', 'admin_supervisions'],
          },
          {
            name: "Documents",
            title: "Documents",
            i18n: "Documents",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'admin_documents'],
          },
          {
            name: "CompanyDashboard",
            title: "CompanyDashboard",
            i18n: "CompanyDashboard",
            subGroup: true,
            requiredRoles: ['super_admin', 'admin', 'user'],
          }
        ]
      }
      // {
      //   title: "Regalias",
      //   name: "Royalties",
      //   i18n: "Royalties",
      //   group: "Royalties",
      //   icon: "mdi-cash-refund",
      //   module: 18,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // }
    ]
  },
  {
    title: "Administración",
    name: "Administrative",
    i18n: "Administrative",
    group: "administrative",
    icon: "mdi-group",
    modules: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    items: [
      {
        title: "Talento Humano",
        name: "HumanTalent",
        i18n: "HumanTalent",
        group: "human-talent",
        icon: "mdi-sitemap",
        subGroup: true,
        module: 2,
        items: [
          {
            name: "adminPanel",
            title: "Principal",
            i18n: "Dashboard",
            subGroup: true,
            requiredRoles: ['super_admin','admin', 'talent_dashboard'],
          },
          {
            name: "positions",
            title: "Positions",
            i18n: "Positions",
            subGroup: true,
            requiredRoles: ['super_admin','admin', 'admin_positions'],
          },
// {
//   name: "process",
//   title: "ProcessStock",
//   i18n: "ProcessStock",
//   subGroup: true,
//   requiredRoles: ['super_admin','admin','admin_processes'],
// },

          {
            name: "manuals",
            title: "ManualsStock",
            i18n: "ManualsStock",
            subGroup: true,
            requiredRoles: ['super_admin','admin','admin_processes'],
          },
          {
            name: "Metrics",
            title: "Metrics",
            i18n: "Metrics",
            subGroup: true,
            requiredRoles: ['super_admin','admin', 'admin_positions'],
          },
          {
            name: "JobBank",
            title: "JobBank",
            i18n: "JobBank",
            subGroup: true,
            requiredRoles: ['super_admin','admin'],
          },
          {
            name: "Profile",
            title: "Profile",
            i18n: "Profile",
            subGroup: true,
            requiredRoles: ['super_admin','admin', 'admin_positions'],
          }
        ]
      }
      // {
      //   title: "B2B",
      //   name: "B2B",
      //   i18n: "B2B",
      //   group: "B2B",
      //   icon: "mdi-briefcase",
      //   module: 3,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Compras",
      //   name: "Purchases",
      //   i18n: "Purchases",
      //   group: "Purchases",
      //   icon: "mdi-shopping",
      //   module: 4,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Inventario",
      //   name: "Stock",
      //   i18n: "Stock",
      //   group: "Stock",
      //   icon: "mdi-clipboard-list",
      //   module: 5,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Activos Fijos",
      //   name: "FixedAssets",
      //   i18n: "FixedAssets",
      //   group: "FixedAssets",
      //   icon: "mdi-format-list-bulleted-type",
      //   module: 6,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Facturación",
      //   name: "Billing",
      //   i18n: "Billing",
      //   group: "Billing",
      //   icon: "mdi-text-box-check",
      //   module: 7,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "POS",
      //   name: "POS",
      //   i18n: "POS",
      //   group: "POS",
      //   icon: "mdi-cellphone-lock",
      //   module: 8,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Cuentas por Cobrar",
      //   name: "BalanceReceivable",
      //   i18n: "BalanceReceivable",
      //   group: "BalanceReceivable",
      //   icon: "mdi-credit-card-plus",
      //   module: 9,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Cuentas por Pagar",
      //   name: "BalancePayable",
      //   i18n: "BalancePayable",
      //   group: "BalancePayable",
      //   icon: "mdi-credit-card-minus",
      //   module: 10,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Bancos",
      //   name: "Banks",
      //   i18n: "Banks",
      //   group: "Banks",
      //   icon: "mdi-bank",
      //   module: 11,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // },
      // {
      //   title: "Contabilidad",
      //   name: "Accounting",
      //   i18n: "Accounting",
      //   group: "Accounting",
      //   icon: "mdi-chart-bar-stacked",
      //   module: 12,
      //   items: [
      //     {
      //       name: "Operational",
      //       title: "Operational",
      //       i18n: "Dashboard"
      //     }
      //   ]
      // }
    ]
  }
  // {
  //   title: "Mercadeo",
  //   name: "Marketing",
  //   i18n: "Marketing",
  //   group: "Marketing",
  //   icon: "mdi-group",
  //   modules: [19, 20, 21, 22],
  //   items: [
  //     {
  //       title: "Gestión de Mercadeo",
  //       name: "MarketingManagement",
  //       i18n: "MarketingManagement",
  //       group: "MarketingManagement",
  //       icon: "mdi-bullhorn",
  //       module: 19,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "CRM",
  //       name: "CRM",
  //       i18n: "CRM",
  //       group: "CRM",
  //       icon: "mdi-account-group",
  //       module: 20,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "Referidos",
  //       name: "Referrals",
  //       i18n: "Referrals",
  //       group: "Referrals",
  //       icon: "mdi-file-tree",
  //       module: 21,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "Encuestas",
  //       name: "Polls",
  //       i18n: "Polls",
  //       group: "Polls",
  //       icon: "mdi-account-switch",
  //       module: 22,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: "Inteligencia de Negocios",
  //   name: "BusinessIntelligence",
  //   i18n: "BusinessIntelligence",
  //   group: "BusinessIntelligence",
  //   icon: "mdi-group",
  //   modules: [23, 24],
  //   items: [
  //     {
  //       title: "Control de Gestión",
  //       name: "ManagementControl",
  //       i18n: "ManagementControl",
  //       group: "ManagementControl",
  //       icon: "mdi-chart-areaspline",
  //       module: 23,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "Presupuestos",
  //       name: "Budgets",
  //       i18n: "Budgets",
  //       group: "Budgets",
  //       icon: "mdi-calculator-variant",
  //       module: 24,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: "Utilidades",
  //   name: "Utilities",
  //   i18n: "Utilities",
  //   group: "Utilities",
  //   icon: "mdi-group",
  //   modules: [25, 26, 27],
  //   items: [
  //     {
  //       title: "Mailing",
  //       name: "Mailing",
  //       i18n: "Mailing",
  //       group: "Mailing",
  //       icon: "mdi-card-account-mail",
  //       module: 25,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "Proyectos",
  //       name: "Projects",
  //       i18n: "Projects",
  //       group: "Projects",
  //       icon: "mdi-source-branch",
  //       module: 26,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     },
  //     {
  //       title: "Nómina",
  //       name: "Payroll",
  //       i18n: "Payroll",
  //       group: "Payroll",
  //       icon: "mdi-cash-multiple",
  //       module: 27,
  //       items: [
  //         {
  //           name: "Operational",
  //           title: "Operational",
  //           i18n: "Dashboard"
  //         }
  //       ]
  //     }
  //   ]
  // }
];
