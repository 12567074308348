<template>
  <v-navigation-drawer
    app
    width="280"
    id="app-drawer"
    v-model="drawer"
    enable-resize-watcher
    floating
    mini-variant-width="80"
    class="vuse-sidebar"
    :class="!semidark ? 'neu-glow' : ''"
    :mini-variant.sync="miniSidebar"
    :expand-on-hover="miniSidebar"
    :left="!isSidenavPostionRight"
    :right="isSidenavPostionRight"
    :clipped="isClippedOver"
    :dark="semidark"
    @update:mini-variant="handleMiniSidebarHover($event)"
  >
    <!-- App Logo -->
    <v-sheet color="transparent" :height="!isClippedOver ? 64 : 56">
      <v-list-item
        v-if="!isClippedOver"
        :class="{ 'logo-section': true, 'logo-background': true, 'semi-dark': semidark }"
      >
        <v-list-item-avatar tile size="auto" class="mx-0" v-show="miniSidebar">
          <v-img
            src="/static/default/logo_blanco--solo-nube.svg"
            width="100%"
            max-width="50"
          /> </v-list-item-avatar
        ><v-list-item-avatar tile size="auto" class="mx-0" v-show="!miniSidebar">
          <v-img max-height="45" src="/static/default/logo_blanco.svg" width="100%" />
        </v-list-item-avatar>
      </v-list-item>
    </v-sheet>
    <v-list
      id="app-sidebar"
      class="nav-drawer transparent"
      dense
      expand="expand"
      v-bind="{ ...activeItemStyle }"
    >
      <template v-for="(item, i) in itm">
        <!-- 1 -->
        <ListGroup v-bind="item" :key="i" v-if="item.items" />
        <v-subheader
          v-else-if="item.header"
          class="heading-text ml-2 nav-subheader"
          :key="`${i}-item`"
          :class="{ 'mt-3': i !== 0 }"
        >
          <v-icon>more_horiz</v-icon>
          <span>
            {{ item.header }}
          </span>
        </v-subheader>
        <NavigationItem v-else :key="`si-${i}`" v-bind="item" />
      </template>
      <v-spacer class="mb-5" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import appDrawerItems from '@/config/navigation-items'
  import ListGroup from '@/components/UI/NavigationItems/ListGroup'
  import NavigationItem from '@/components/UI/NavigationItems/NavigationItem'
  import has from 'lodash/has'
  // Decrypt
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'Sidebar',
    data() {
      return {
        items: appDrawerItems,
        miniSidebarHover: false,
      }
    },
    watch: {
      isMinSideNav() {},
      assignedModules() {
        this.filterModules()
      },
    },
    computed: {
      activeItemStyle: (state) => state.activeMenuStyle,
      ...mapGetters('navigations', [
        'isVisibleSideNav',
        'isMinSideNav',
        'activeItemStyle',
        'isSidenavPostionRight',
        'isClippedOver',
      ]),
      ...mapGetters('scheme', ['semidark']),
      ...mapGetters(['userModules']),
      activemenu() {
        return this.$vuetify.theme.activemenu
      },
      miniSidebar: {
        get() {
          return this.isMinSideNav
        },
        set(value) {
          this.$store.dispatch('navigations/setSidenavVisibility', value)
          return value
        },
      },

      drawer: {
        get() {
          if (this.isVisibleSideNav) {
            return this.isVisibleSideNav
          }
          return null
        },
        set(value) {
          this.$store.dispatch('navigations/setSidenavVisibility', value)
        },
      },
      itm() {
        return this.items.map(this.addLanguagePrefix)
      },
      assigneMdodules() {
        return this.userModules &&
          decryptData(this.userModules) &&
          decryptData(this.userModules).length > 0
          ? decryptData(this.userModules)
          : []
      },
      // handleMiniSidebarHover(event) {
      //   this.miniSidebarHover = !event
      // },
    },
    components: {
      ListGroup,
      NavigationItem,
    },
    methods: {
      filterModules() {
        this.items = this.items.filter((nav) => {
          const elementsOfArray2PresentInArray1 = this.assignedModules.filter(
            (module) => {
              if (nav.header || !nav.modules) {
                return true
              } else if (nav.modules) {
                return nav.modules.includes(module)
              } else {
                return false
              }
            }
          )

          if (elementsOfArray2PresentInArray1.length > 0) {
            return true
          } else {
            return false
          }
        })
      },
      addLanguagePrefix(item) {
        const { items, ...props } = item
        const newVal = has(props, 'header')
          ? { header: this.$t(`Vuse.AppDrawer.${item.i18n}`) }
          : { title: this.$t(`Vuse.AppDrawer.${item.i18n}`) }
        const newItem = {
          ...props,
          ...newVal,
        }

        if (items) {
          newItem.items = items.map(this.addLanguagePrefix)
        }

        return newItem
      },
      handleMiniSidebarHover(event) {
        this.miniSidebarHover = event
      },
    },
  }
</script>
<style lang="scss" scoped></style>
