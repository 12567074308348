<template>
  <v-app-bar
    app
    flat
    :clipped-left="isClippedLeft"
    :clipped-right="isClippedRight"
    v-if="isVisibleHeader"
    v-bind="bindStyle"
    :dense="isDense"
    :hide-on-scroll="isHideOnScroll"
    :shrink-on-scroll="isShrinkOnScroll"
    min-height="64"
    class="app-header"
  >
    <v-list-item class="pl-0" v-if="isClippedOver || $vuetify.breakpoint.mdAndDown">
      <v-list-item-avatar
        tile
        size="auto"
        v-show="$vuetify.breakpoint.smAndDown && !isVisibleSideNav"
      >
        <v-img src="/static/default/logo-gris--nube.svg" alt="" />
      </v-list-item-avatar>

      <v-list-item-avatar
        tile
        size="auto"
        v-show="$vuetify.breakpoint.mdAndUp && !isVisibleSideNav"
      >
        <v-img src="/static/default/logo-gris.svg" alt="" />
      </v-list-item-avatar>
      <v-list-item-action v-if="$vuetify.breakpoint.lgAndUp">
        <v-btn
          @click.stop="toggleMiniVariant"
          small
          fab
          :class="!bindStyle.color ? 'neu-glow' : ''"
        >
          <v-icon
            :color="!bindStyle.color ? 'primary' : bindStyle.color"
            v-if="isMinSideNav"
            >double_arrow</v-icon
          >
          <v-icon :color="!bindStyle.color ? 'primary' : bindStyle.color" v-else
            >menu_open</v-icon
          >
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <div v-if="$vuetify.breakpoint.lgAndUp">
      <!-- toggle Mini variant -->
      <v-btn
        @click.stop="toggleMiniVariant"
        small
        fab
        :class="!bindStyle.color ? 'neu-glow' : ''"
        v-if="!isClippedOver"
      >
        <v-icon
          :color="!bindStyle.color ? 'primary' : bindStyle.color"
          v-if="isMinSideNav"
          >double_arrow</v-icon
        >
        <v-icon :color="!bindStyle.color ? 'primary' : bindStyle.color" v-else
          >menu_open</v-icon
        >
      </v-btn>
    </div>
    <v-spacer />

    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      class="mr-3"
      small
      fab
      :class="!bindStyle.color ? 'neu-glow' : ''"
      @click="handleSidenavDrawer()"
    >
      <v-icon :color="!bindStyle.color ? 'primary' : bindStyle.color">menu</v-icon>
    </v-btn>

    <!-- Company Menu -->
    <v-menu offset-y min-width="160">
      <template v-slot:activator="{ on }">
        <v-btn
          style="width: auto; border-radius: 12px"
          class="mr-3"
          v-on="on"
          small
          fab
          :class="!bindStyle.color ? 'neu-glow' : ''"
        >
          <v-icon class="mx-1" v-text="'mdi-home-assistant'" />
          <span style="margin: auto 5px">{{
            currentCompany.name + ' (N' + currentCompany.level_id + ')'
          }}</span>
        </v-btn>
      </template>
      <v-list
        nav
        dense
        class="neu-glow-primary with-radius"
        style="max-height: 270px; overflow-y: auto"
      >
        <template v-for="(item, index) in companies">
          <v-divider v-if="item.divider" :key="'if-' + index" />
          <v-list-item :key="index" @click="changeCurrentCompany(item)" v-else>
            <v-list-item-content>
              <v-list-item-title
                v-if="index === companies.length - 1 && company.id !== currentCompany.id"
                v-text="'Volver a ' + item.name + ' (N' + item.level_id + ')'"
              />
              <v-list-item-title
                v-else
                v-text="item.name + ' (N' + item.level_id + ')'"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <!-- User Profile Menu -->
    <v-menu offset-y min-width="160">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon class="mr-3">
          <v-avatar size="40" v-if="bindStyle.color">
            <v-img :src="avatar" />
          </v-avatar>
          <vuse-avatar size="40" :src="avatar" v-else />
        </v-btn>
      </template>
      <v-list nav dense class="">
        <template v-for="(item, index) in handleUserProfileMenuLang">
          <v-divider v-if="item.divider" :key="'if-' + index" />
          <v-list-item v-else :key="'else-' + index" @click="handleActions(item.id)">
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { availableLocale } from '@/config/locale'

  // Decrypt
  import { encryptData, decryptData } from '@/utils/encryption'

  export default {
    name: 'Toolbar',
    props: {
      handleSettingsDrawer: Function,
    },
    data() {
      return {
        items: [
          { id: 1, icon: 'person', text: 'Profile', lang: 'en' },
          // { id: 2, icon: 'account_balance_wallet', text: 'Account', lang: 'en' },
          // { id: 3, icon: 'settings', text: 'Settings', lang: 'en' },
          // { id: 4, icon: 'inbox', text: 'Inbox', lang: 'en' },
          { id: 5, divider: true, lang: 'en' },
          { id: 6, icon: 'power_settings_new', text: 'Logout', lang: 'en' },
          { id: 7, icon: 'person', text: 'Perfil', lang: 'es' },
          // {
          //   id: 8,
          //   icon: 'account_balance_wallet',
          //   text: 'Mi cuenta',
          //   lang: 'es',
          // },
          // { id: 9, icon: 'settings', text: 'Configuraciones', lang: 'es' },
          // { id: 10, icon: 'inbox', text: 'Bandeja de entrada', lang: 'es' },
          { id: 11, divider: true, lang: 'es' },
          {
            id: 12,
            icon: 'power_settings_new',
            text: 'Cerrar sesión',
            lang: 'es',
          },
        ],
        langs: availableLocale,
      }
    },
    watch: {},
    computed: {
      name() {
        if (this.$session.get('tokenSession')) {
          return decryptData(this.$session.get('userName'))
        } else {
          return false
        }
      },
      company() {
        if (this.userCompany) {
          const company = decryptData(this.userCompany)
          company.levelCode = 'L' + company.level_id
          return company
        } else {
          return false
        }
      },
      currentCompany() {
        if (this.userCurrentCompany) {
          const company = decryptData(this.userCurrentCompany)
          company.levelCode = 'L' + company.level_id
          return company
        } else {
          return false
        }
      },
      companies() {
        if (
          this.$session.get('companies') &&
          decryptData(this.$session.get('companies'))
        ) {
          let companies = decryptData(this.$session.get('companies'))
          companies = companies.filter(
            (company) =>
              company.id !== this.currentCompany.id && company.id !== this.company.id
          )
          companies.forEach((company) => {
            company.levelCode = 'L' + company.level_id
          })
          if (this.company.id !== this.currentCompany.id) {
            companies.push({ divider: true }, this.company)
          }

          return companies
        } else {
          return []
        }
      },
      avatar() {
        if (
          this.$session.get('userAvatar') &&
          decryptData(this.$session.get('userAvatar'))
        ) {
          return decryptData(this.$session.get('userAvatar')).route
        } else {
          return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png'
        }
      },
      activeItemStyle: (state) => state.activeMenuStyle,
      ...mapGetters('navigations', [
        'isVisibleHeader',
        'isSidenavPostionRight',
        'isVisibleSideNav',
        'isClippedOver',
        'isMinSideNav',
        'isDense',
        'isProminent',
        'isHideOnScroll',
        'isShrinkOnScroll',
        'isFloating',
      ]),
      ...mapGetters('scheme', ['header']),
      ...mapGetters(['locale', 'userCurrentCompany', 'userCompany']),
      isClippedRight() {
        return this.isClippedOver && this.isSidenavPostionRight
      },
      isClippedLeft() {
        return this.isClippedOver && !this.isSidenavPostionRight
      },
      bindStyle() {
        const { color, dark, classes } = this.header
        return {
          class: {
            'vuse-header': true,
            'with-radius': true,
            [classes]: true,
            'mx-6 mt-3': this.isFloating && !this.isClippedOver,
          },
          dark,
          color,
        }
      },
      currentLocaleImg() {
        return this.langs.find((item) => item.value === this.locale).img
      },
      handleUserProfileMenuLang() {
        return this.items.filter((item) => item.lang === this.locale)
      },
    },
    created() {
      this.setName()
    },
    methods: {
      changeCurrentCompany(company) {
        this.$store.commit('updateUserCurrentCompany', encryptData(company))
      },
      toggleMiniVariant() {
        this.$store.dispatch('navigations/setMiniVariant')
      },
      handleSidenavDrawer() {
        this.$store.dispatch('navigations/setSidenavVisibility')
      },
      handleInternationalization(value) {
        this.$store.dispatch('setLocale', value)
      },
      handleActions(id) {
        switch (id) {
          case 1:
            this.$router.push({
              name: 'administrative/human-talent/Profile',
            })
            break
          case 7:
            this.$router.push({
              name: 'administrative/human-talent/Profile',
            })

            break
          case 6:
            this.logout()
            break
          case 12:
            this.logout()
            break
          default:
            break
        }
      },
      setName() {
        this.items[0].text = this.name
        this.items[3].text = this.name
      },
      logout() {
        this.$swal({
          title: 'Hasta pronto ' + this.name,
          html: 'Regresa cuando quieras para seguir disfrutando de nuestros servicios',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
        this.$axios
          .get('auth/logout')
          .then(() => {
            this.$session.destroy()
          })
          .catch(() => {
            this.$session.destroy()
          })
          .finally(() => {
            setTimeout(() => {
              this.$router.push('/login')
            }, 4000)
          })
      },
    },
  }
</script>
<style lang="scss">
  .app-header {
    .v-toolbar__content {
      min-height: 64px;
    }
  }
</style>
