<template>
  <!-- 1 -->
  <v-list-group
    :group="group"
    :prepend-icon="icon"
    active-class="act-menu"
    color="primary"
    class="first-level-group"
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-for="(subitem, i) in items">
      <!-- Sub-group  -->
      <ListSubGroup
        v-if="
          Object.keys(subitem).includes('items') &&
          assignedModules.includes(subitem.module)
        "
        v-bind="subitem"
        :parentGroup="subitem.subGroup ? group : ''"
        :key="`${i}-subitems`"
        :toBind="genChildTarget"
      />

      <v-list-item
        v-else-if="!Object.keys(subitem).includes('items')"
        :to="genChildTarget(subitem)"
        :key="i"
        :href="Object.keys(subitem).includes('href') ? subitem.href : null"
        :disabled="Object.keys(subitem).includes('disabled') && subItem.disabled"
        :target="Object.keys(subitem).includes('target') ? subItem.target : ''"
        ripple
      >
        <ItemIcon :icon="subitem.icon" :title="subitem.title" />
        <v-list-item-title v-text="subitem.title" />
        <v-badge inline :content="subitem.badge" v-if="subitem.badge" />
      </v-list-item>
    </template>
  </v-list-group>
</template>
<script>
  import { decryptData } from '@/utils/encryption'
  import { camel } from '@/utils/helpers'
  import ListSubGroup from './ListSubGroup'
  import ItemIcon from './ItemIcon'

  export default {
    props: {
      group: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
      },
      component: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        assignedModules: decryptData(this.$session.get('userModules')).map(
          (module) => module.id
        ),
      }
    },
    components: {
      ListSubGroup,
      ItemIcon,
    },
    methods: {
      genChildTarget(subItem) {
        if (Object.keys(subItem).includes('href')) return
        if (this.component !== '') {
          return {
            name: this.component,
            params: {
              section: this.group,
              component: subItem.name,
            },
          }
        }
        return { name: `${this.group}/${camel(subItem.name)}` }
      },
      toCamelCase(str) {
        return str ? camel(str) : ''
      },
    },
  }
</script>
<style lang="scss">
  .v-application--is-ltr .v-list-item__action:first-child,
  .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 5px;
  }
  .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
  .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
  .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0px;
  }
</style>
