<template>
  <!-- 2 -->
  <v-list-group :group="group" class="second-level-group">
    <template v-slot:activator>
      <ItemIcon :icon="icon" :title="title" />
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <div v-for="(subitem, i) in items" :key="i">
      <v-list-item
        v-if="handleAssignedRoles(subitem)"
        :to="getTargetRoute(subitem)"
        ripple
        :exact="subitem.exact"
      >
        <ItemIcon :icon="subitem.icon" :title="subitem.title" />
        <v-list-item-title v-text="subitem.title" />
        <v-badge inline :content="subitem.badge" v-if="subitem.badge" />
      </v-list-item>
    </div>
  </v-list-group>
</template>

<script>
  import { decryptData } from '@/utils/encryption'
  import { camel } from '@/utils/helpers'
  import ItemIcon from './ItemIcon'

  export default {
    props: {
      group: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
      },
      title: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
      },
      toBind: {
        type: Function,
      },
      parentGroup: {
        type: String,
      },
    },
    components: {
      ItemIcon,
    },
    computed: {
      roles() {
        return decryptData(this.$session.get('roles')).map((x) => {
          return x.name
        })
      },
    },
    methods: {
      getTargetRoute(subItem) {
        if (subItem.subGroup === true) {
          return { name: `${this.parentGroup}/${this.group}/${camel(subItem.name)}` }
        } else {
          this.toBind(subItem)
        }
      },
      toCamelCase(str) {
        return str ? camel(str) : ''
      },
      handleAssignedRoles(subitem) {
        if (subitem.requiredRoles) {
          let count = 0
          subitem.requiredRoles.forEach((element) => {
            if (this.roles.includes(element)) {
              count++
            }
          })
          if (count > 0) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },
    },
  }
</script>
